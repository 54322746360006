import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import { UserProvider } from "./components/Middleware/UserContext";
import { ThemeProvider } from "./components/Theme/ThemeContext";
import AppRoutes from "./Routes";
import { Analytics } from "@vercel/analytics/react";
import "./App.css";
import "./Button.css"
import Footer from "./components/Footer/Footer";
import Navbar from './components/navbar/Navbar';
import ImageModel from "./components/ImageModel";

// Set the base URL for Axios
// axios.defaults.baseURL = "http://localhost:5000";

axios.defaults.baseURL = "https://ndr-seven.vercel.app";

const App = () => {
  return (
    <UserProvider>
      <ThemeProvider>
        <Analytics />
        <Router>
          <Navbar />
          <AppRoutes />
          <ImageModel/>
          <Footer />
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
};

export default App;
