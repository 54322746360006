import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../Theme/ThemeContext";
import DOMPurify from "dompurify";

import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import share from "../Middleware/share";
import "./NewsDetailPage.css";
import NewsTitlesBulletLine from "../BulletLine/NewsTitlesBulletLine";
import AdList from "../Ads/AdList";
import ErrorPage from "../Error/ErrorPage";

const NewsDetailPage = () => {
  const [article, setArticle] = useState(null);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const youtubePlayerRef = useRef(null);
  const { theme } = useTheme();

  document.body.classList.toggle("dark-mode", theme === "dark");

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/news/${id}`);
        setArticle(response.data);
      } catch (error) {
        console.error("Error fetching the article:", error);
      }
    };

    fetchArticle();
  }, [id]);

  useEffect(() => {
    if (article) {
      document.title = article.title;
    }
  }, [article]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('/api/news/latest-news');
        const sortedNews = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNews(sortedNews);
        setLoading(false);
      } catch (error) {
        console.error('There was an error fetching the news!', error);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const getEmbedUrl = (youtubeLink) => {
    try {
      const url = new URL(youtubeLink);
      let videoId;

      if (url.hostname === "youtu.be") {
        videoId = url.pathname.slice(1);
      } else if (
        url.hostname.includes("youtube.com")
      ) {
        videoId = url.searchParams.get("v");
      }

      return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
    } catch (error) {
      console.error("Invalid YouTube URL", error);
      return null;
    }
  };

  const renderYouTubeVideo = () => {
    if (article?.youtubeLink) {
      return (
        <div className="youtube-video">
          <iframe
            className="embed-responsive-item"
            src={getEmbedUrl(article.youtubeLink)}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            ref={youtubePlayerRef}
          ></iframe>
        </div>
      );
    }
    return null;
  };

  if (!article) {
    return (
      <div className="container text-center mt-4">
        <Skeleton height={50} width={`80%`} />
        <br />
        <Skeleton height={300} />
        <br />
        <Skeleton height={30} width={200} />
        <br />
        <Skeleton height={20} width={150} />
        <br />
        <Skeleton height={20} width={`60%`} count={3} />
        <br />
        <Skeleton height={50} width={200} />
      </div>
    );
  }

  const recommendedNews = news.filter((item) => item._id !== id);

  return (
    <div className="container mt-4 text-center no-copy">
      <br />
      <NewsTitlesBulletLine news={news} />
      <br />
      <AdList />
      <div className="mt-4 text-center">
        <hr />
        <br />
        <h1 className="display-4 fw-bold ">{article.title}</h1>


        {article.images?.length > 1 ? (
          <Slider dots={true} infinite={true} speed={500} slidesToShow={1} slidesToScroll={1} autoplay={true} autoplaySpeed={3000}>
            {article.images.map((image, index) => (
              <div key={index} className="image-container">
                <img
                  src={image}
                  alt={`News ${index + 1}`}
                  className="img-fluid image"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <div className="image-container">
            <img
              src={article.images?.[0] || "default-image.jpg"}
              alt="News"
              className="img-fluid image"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        )}
      </div>

      <button className="btn btn-primary button-share mt-3" onClick={() => share(article)}>
        <FontAwesomeIcon icon={faShareAlt} /> शेयर करे
      </button>

      <br />
      <h6 className="fs-5 text-start">{moment(article.date).format("DD-MM-YYYY")}</h6>
      <h5 className="fs-5 text-start">{article.city || "चित्तौड़गढ़"}</h5>
      <h5 className="fs-5 text-start">पत्रकार {article.reporter?.username}</h5>

      <div className="mx-auto text-start preformatted-text">
        <p className="fs-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }}></p>
      </div>

      <div className="youtube">{renderYouTubeVideo()}</div>
{/* 
      <button className="btn btn-primary mt-3" onClick={() => window.open("https://www.youtube.com/@newsdayrajasthan", "_blank")}>
        हमरे यूट्यूब चैनल को सबस्क्राइब करे..!!
      </button> */}

      <div>
        <br />
        <hr />
        <h1>और समाचार पढे</h1>
        
        <div className="row">
          {recommendedNews.slice(0, 10).map((article) => (
            <div className="col-md-4" key={article._id}>
              <Link to={`/news/${article._id}`} className="news-card" onClick={() => window.scrollTo(0, 700)}>
                <div className="image-container">
                  <img
                    src={article.images?.[0] || "default-image.jpg"}
                    alt={article.title}
                    className="card-image"
                    style={{ maxWidth: "100vw", height: "40vh" }}
                  />
                </div>
                <div className="card-details">
                  <h2 className="card-title">{article.title}</h2>
                  <h6 className="card-date">{moment(article.date).format("DD-MM-YYYY")}</h6>
                  <p className="card-location">{article.city}, {article.state}</p>
                  <Link to={`/news/${article._id}`} className="read-more">...पढ़ना जारी रखे</Link>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsDetailPage;
