import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Table,
  Button,
  Container,
  Form,
  Collapse,
  Modal,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import share from "../Middleware/share";
import { useTheme } from "../Theme/ThemeContext";

const NewsItemSkeleton = () => (
  <tr>
    <td colSpan="2">
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
      <div className="skeleton-row mb-3"></div>
    </td>
  </tr>
);

const ReporterDashboard = () => {
  const [news, setNews] = useState([]);
  const [form, setForm] = useState({
    title: "",
    content: "",
    city: "",
    state: "",
    youtubeLink: "",
    file: "",
    date: "",
  });
  const [files, setFiles] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedId, setExpandedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const { theme } = useTheme();

  useEffect(() => {
    fetchReporterNews();
  }, []);

  const fetchReporterNews = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in localStorage");
        return;
      }

      const response = await axios.get("/api/news/own", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNews(response.data);
    } catch (error) {
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = editMode ? `/api/news/update/${editId}` : "/api/news/create";
    const data = new FormData();

    data.append("title", form.title);
    data.append("content", form.content);
    data.append("city", form.city || "चित्तौड़गढ़");
    data.append("state", form.state);
    data.append("youtubeLink", form.youtubeLink);

    // Get current date in YYYY-MM-DD format
    const currentDate = moment().format("YYYY-MM-DD");

    // Get current time in HH:mm format
    const currentTime = moment().format("HH:mm");

    const dateTimeString = `${currentDate} ${currentTime}`;

    // Append date with current time
    data.append("date", dateTimeString);

    Array.from(files).forEach((file) => data.append("images", file));

    try {
      setLoading(true);
      const response = await axios({
        method: editMode ? "put" : "post",
        url: url,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const updatedNews = editMode
        ? news.map((item) => (item._id === editId ? response.data.news : item))
        : [response.data.news, ...news];

      setNews(
        updatedNews.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );

      setForm({
        title: "",
        content: "",
        city: "",
        state: "",
        youtubeLink: "",
        file: "",
        date: "",
      });
      setFiles([]);
      setEditMode(false);
      setEditId(null);
    } catch (error) {
      console.error("There was an error creating/updating the news!", error);

      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === "File too large"
      ) {
        alert("File size is too large. Please upload files smaller than 5MB.");
      } else {
        alert(
          "There was an error creating/updating the news. Please try again later."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (newsId) => {
    try {
  
      const response = await axios.get(`/api/news/${newsId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      setForm(response.data); // Assuming API returns the news data for the form
      setEditMode(true);
      setEditId(newsId);
    } catch (error) {
      console.error("Error fetching news for edit:", error);
    } 
  };
  

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this news article?"
    );
    if (!confirmed) return;

    try {
      setLoading(true);
      await axios.delete(`/api/news/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const updatedNews = news.filter((item) => item._id !== id);
      setNews(updatedNews);
    } catch (error) {
      console.error("There was an error deleting the news!", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedNews(null);
  };

  const handleViewDetails = (newsItem) => {
    setSelectedNews(newsItem);
    setShowModal(true);
  };


  const districts = [
    "चित्तौड़गढ़", "अजमेर", "अलवर", "बांसवाड़ा", "बारां", "बाड़मेर", "भरतपुर", "भीलवाड़ा",
    "बीकानेर", "बूंदी", "चित्तौड़गढ़", "चूरू", "दौसा", "धौलपुर", "डूंगरपुर",
    "हनुमानगढ़", "जयपुर", "जैसलमेर", "जालौर", "झालावाड़", "झुंझुनू",
    "जोधपुर", "करौली", "कोटा", "नागौर", "पाली", "प्रतापगढ़", "राजसमंद",
    "सवाई माधोपुर", "सीकर", "सिरोही", "श्रीगंगानगर", "टोंक", "उदयपुर"
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3,4,5,6, false] }], // Header styles
      [{ font: [] }], // Font types
      [{ color: [] }, { background: [] }], // Text color & background color
      [{ align: [] }], // Text alignment
      ["bold", "italic", "underline", "strike"], // Bold, Italic, Underline, Strikethrough
      [{ list: "ordered" }, { list: "bullet" }], // Ordered & unordered lists
      [{ indent: "-1" }, { indent: "+1" }], // Indent options
      ["blockquote", "code-block"], // Blockquote & code block
      [{ direction: "rtl" }], // Right to left text
      ["link", "image", "video"], // Insert link, image, and video
      ["clean"], // Remove formatting
    ],
  };


  return (
    <Container className="container-custom">
      <br />
      <br />
      <br />

      <Form className="form-custom" onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <Form.Group controlId="formTitle">
              <Form.Label>
                <h4>Heading</h4>
              </Form.Label>
              <Form.Control
                type="text"
                className="heading"
                placeholder="Enter title"
                value={form.title}
                onChange={(e) => setForm({ ...form, title: e.target.value })}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={6}>
            <Form.Group controlId="formCity">
              <Form.Label>
                <h4>City</h4>
              </Form.Label>
              <Form.Control
                as="select"
                value={form.city}
                onChange={(e) => setForm({ ...form, city: e.target.value })}
              >
                {districts.map((district, index) => (
                  <option key={index} value={district}>
                    {district}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formYoutubeLink">
              <Form.Label>
                <h4>YouTube Link</h4>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter YouTube link"
                value={form.youtubeLink}
                onChange={(e) =>
                  setForm({ ...form, youtubeLink: e.target.value })
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col md={6}>
            <Form.Group controlId="formDate">
              <Form.Label>
                <h4>Date</h4>
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter date"
                value={form.date}
                onChange={(e) => setForm({ ...form, date: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formImages">
              <Form.Label>
                <h4>Images</h4>
              </Form.Label>
              <Form.Control
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <br></br>

        <Form.Group controlId="formContent">
          <Form.Label>
            <h4>Context</h4>
          </Form.Label>
          <ReactQuill
            className="context"
            style={{ height: "100vh" }}
            theme="snow"
            value={form.content}
            onChange={(content) => setForm({ ...form, content })}
            modules={modules} // Use the custom toolbar
            placeholder="Enter content"
          />
        </Form.Group>

        <br />

        <Button variant="primary" type="submit" className="custom-btn btn-7">
          {editMode ? "Update News" : "Create News"}
        </Button>
      </Form>

      {loading ? (
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <h2 className="mt-4">Your News</h2>
          <Table striped bordered hover className={theme === "dark" ? "" : ""}>
            <thead>
              <tr>
                <th>Title</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? Array.from({ length: 5 }).map((_, index) => (
                  <NewsItemSkeleton key={index} />
                ))
                : news.map((item) => (
                  <React.Fragment key={item._id}>
                    <tr>
                      <td onClick={() => handleViewDetails(item)} style={{ width: '70%' }}>
                        <h4>{item.title}</h4>
                        <br />
                        <h6 style={{ color: "red" }}>{item.views} views</h6>
                        <br />
                        {item.city}
                        <br />
                        {moment(item.date)
                          .subtract(5, "hours")
                          .subtract(30, "minutes")
                          .format("DD-MM-YYYY, h:mm A")}

                        {/* {moment(item.date)
                            .format("Do MMMM YYYY, h:mm A")} */}
                        <hr></hr>
                      </td>
                      <td style={{ width: '10%' }}>
                        <Button
                          variant="warning"
                          onClick={() => handleEdit(item._id)}
                          className="mt-2 me-2"
                        >
                          Edit
                        </Button>

                        <Button
                          variant="danger"
                          onClick={() => handleDelete(item._id)}
                          className="mt-2 me-2"
                        >
                          Delete
                        </Button>

                        <Button
                          variant="primary"
                          onClick={() => share(item)}
                          className="mt-2 me-2"
                        >
                          <FontAwesomeIcon icon={faShareAlt} /> Share
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <Collapse in={expandedId === item._id}>
                          <div>
                            <h6>{item.views}</h6>
                            <p>{item.content}</p>
                            {item.images && item.images.length > 0 && (
                              <div>
                                <h5>Images:</h5>
                                {item.images.map((img, index) => (
                                  <img
                                    key={index}
                                    src={`${img}`}
                                    alt={`img-${index}`}
                                    className="img-thumbnail"
                                  />
                                ))}
                              </div>
                            )}
                            <p>YouTube Link: {item.youtubeLink}</p>
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </Table>
        </>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header
          closeButton
          className={theme === "dark" ? "modal-dark" : ""}
        >
          <Modal.Title>News Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className={theme === "dark" ? "modal-dark" : ""}>
          {selectedNews && (
            <div>
              <h5>{selectedNews.status}</h5>
              <h6>{selectedNews.views} views</h6>
              <h2>{selectedNews.title}</h2>
              <h6>{selectedNews.reporter.username}</h6>
              <p>
                {moment(selectedNews.date)
                  .subtract(5, "hours")
                  .subtract(30, "minutes")
                  .format("DD-MM-YYYY, h:mm A")}
              </p>
              <p>{selectedNews.content}</p>
              <p>City: {selectedNews.city}</p>
              <p>State: {selectedNews.state}</p>
              {selectedNews.images && (
                <div>
                  <h5>Images</h5>
                  {selectedNews.images.map((image, index) => (
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    <img
                      key={index}
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ maxWidth: "100%", marginBottom: "10px" }}
                    />
                  ))}
                </div>
              )}
              {selectedNews.youtubeLink && (
                <div>
                  <h5>YouTube Link</h5>
                  <p>{selectedNews.youtubeLink}</p>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={theme === "dark" ? "modal-dark" : ""}>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default ReporterDashboard;
