import React, { useState, useEffect } from 'react';

const ImageModal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // Automatically show the modal when the component mounts
  useEffect(() => {
     handleShow(); // Open the modal on component load
  }, []);

  return (
    <div>
      {/* Button is optional now as the modal will open automatically */}
      {/* <button 
        type="button" 
        className="btn btn-primary" 
        onClick={handleShow}
      >
        Launch demo modal
      </button> */}

      {showModal && (
        <div 
          className="modal fade show" 
          id="exampleModalCenter" 
          tabindex="-1" 
          role="dialog" 
          aria-labelledby="exampleModalCenterTitle" 
          aria-hidden="true"
          style={{ display: 'block' }}
        >
          <div className="mx-auto modal-dialog"  style={{maxWidth: 'fit-content', marginTop: '4rem', padding:'1rem'}} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button 
                  type="button" 
                  className="btn-close" 
                  onClick={handleClose} 
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body text-center">
                <img 
                  src="https://res.cloudinary.com/dhhztw6hx/image/upload/v1731988260/bof9xs8rlsawhxcgpun9.jpg" 
                  alt="Full screen" 
                  className="img-fluid" 
                  style={{ maxHeight: '80vh' }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
